@import "./normalize";
@import "./variables";
@import "./form";
@import "./button";
@import "./datepicker/styles";
@import "./radio";
@import "./checkbox";
@import "./select";
@import "./userMenu";
@import "./customCard";

.clinic-axa-host {
  .toast {
    top: 2rem;
    bottom: unset;
  }
}
