@import "../../../../assets/styles/variables";

.StripeElement {
  display: flex;
  align-items: center;
  height: 4.4rem;
  padding: .35rem 1.5rem;
  color: $main-color;
  background-color: white;
  border: 0.1rem solid $border-color;
  width: 100%;

  &.StripeElement--focus {
    //border-color: ;
  }

  &.StripeElement--invalid {
    border-color: $third-color;
  }

  &> div {
    flex: 1;
  }
}
