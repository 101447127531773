@import "variables";

.modalBackground {
  content: '';
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  z-index: 10;
}

.authUserMenuWrapper {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 5rem 10rem 0;

  .authUserMenuButton {
    padding: 1rem 2rem;
    margin-left: 1rem;
    height: 4rem;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $base-font-size;
    background-color: transparent;
    color: $dark-green;
    border: thin solid rgba($dark-color, 0.2);
    font-weight: bold;
    font-family: $form-font;
    outline: none;
    cursor: pointer;

    &:hover {
      color: $third-color;
    }
  }

}

.authUserMenuDialog {
  position: absolute;
  top: 6.5rem;
  right: 10rem;
  left: unset;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 10001;
  border: thin solid $light-gray-color;
  font-family: $form-font;
  overflow: hidden;
  background: $white-color;

  &.login {
    width: 30rem;
  }

  &.register {
    width: 45rem;
  }

  &.opened {
    display: flex;
  }

  .formContainer {
    padding: 2rem;
    margin-bottom: 0;
  }

  .authDialogTitle {


    color: $white-color;
    padding: 1.5rem;
    position: relative;
    background: $login-menu-dark-background;
    font-size: $base-font-size;


    .formContainer {
      padding: 2rem;
      margin-bottom: 0;
    }

    &.opened {
      display: flex;
    }
  }

  .loggedUserMenu {
    display: flex;
    flex-direction: column;
    font-size: $base-font-size;
    list-style: none;
    padding: 2rem 0;
    align-items: stretch;

    .loggedUserMenuItem {
      margin: 0;
      border-radius: 2px;
      display: flex;
      align-items: stretch;

      .menuItemContent {
        display: flex;
        flex: 1;
        color: $login-menu-text-color;
        font-size: $base-font-size;
        padding: 1rem 2rem;
        text-decoration: none;

        i {
          margin-right: 1rem;
          font-size: $m-font-size;
        }
      }

      &.active,
      &:active,
      &:focus,
      &:hover {
        background: transparentize($third-color, 0.97);

        > .menuItemContent {
          color: $third-color;
          background: transparent;
        }
      }

      .logoutButton {
        background: transparent;
        appearance: none;
        cursor: pointer;
        display: flex;
        flex: 1;
      }
    }
  }
}

.login-user-menu {
  .button-container {
    padding: 0.5rem;

    button {
      width: 100%;
    }
  }
}

.registration-user-menu {
  .button-container {
    padding: 0.5rem;
  }
}


@media (max-width: $breakpoint-xlarge) {
  .authUserMenuWrapper {
    right: 4rem;
  }

  .authUserMenuDialog {
    right: 4rem;
  }
}

@media (max-width: $breakpoint-medium) {

  .authUserMenuWrapper {
    padding: 2rem 1rem 0;
  }

  .authUserMenuDialog {
    right: 1rem;
    left: 1rem;
    top: 13rem;

    &.register {
      width: unset;
    }

    &.login {
      width: unset;
    }
  }
}
