@import "variables";

form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  .form-row {
    float: left;
    display: block;
    margin-bottom: 1rem;
    width: 100%;

    &.companion-controls {
      display: flex;
      align-items: flex-end;
    }

    &.login-form {
      margin: initial;
    }

    .horizontal {
      display: flex;
      flex-direction: row;
      align-items: center;

      .form-label {
        margin-right: 1.5rem;
        width: 3rem;
        text-align: right;
      }
    }

    .form-label {
      display: block;
      width: 100%;
      text-align: left;
      font-weight: 700;
      margin-bottom: .3rem;
      color: $grey-text-color;
      font-size: $base-font-size;
      min-height: 1.7rem;
    }

    .form-label-hide {
      display: none;
    }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=tel],
    input[type=password] {
      display: block;
      width: 100%;
      height: 4.4rem;
      padding: .35rem 1.5rem;
      font-family: 'Ubuntu', sans-serif;
      font-size: $base-m-font-size;
      font-weight: 400;
      line-height: 1.5;
      color: $input-text-color;
      background-color: #fff;
      border: 0.1rem solid $border-color;
      border-radius: 0;

      &::placeholder {
        color: $input-text-color;
        opacity: 1;
      }
    }
  }

  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }

  &.hidden {
    visibility: hidden;
    height: 0;
  }

  &.login {
    margin: 2rem 0;
  }

  &.loginMenuForm {
    input {
      &::placeholder {
        font-size: $small-font-size;
      }
    }
  }
}

.button-form-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 0.5rem;

  &.single {
    justify-content: flex-end;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.col-25, .col-33, .col-50, .col-66, .col-75, .col-100 {
  float: left;
  padding: 0.5rem;
}

.col-25 {
  width: 25%;
}

.col-33 {
  width: 33.3333%;
}

.col-50 {
  width: 50%;
}

.col-66 {
  width: 66.66666%;
}

.col-75 {
  width: 75%;
}

.col-100 {
  width: 100%;
}
.btn-primary { // toDo porzadek z buttonami PŻ
  border: none;
  color: $white-color;
  background: $third-color;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1.5rem 4.5rem;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  font-size: $base-font-size;
  margin: 0 0.5rem;
}

@media (max-width: $breakpoint-medium) {
  .button-form-container {
    padding: 1rem;
  }
}

@media (max-width: $breakpoint-large) {
  form {
    .form-row {
      .col-25, .col-33, .col-50, .col-66, .col-75, .col-100 {
        width: 100%;
      }
    }
  }
  .button-form-container {
    padding: 1rem;
  }
}
